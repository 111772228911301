<template>
  <div>
    <div class="erp-form__header header-bar__sticky">
      <div class="erp-form__title">
        {{ formType == 1 ? '新增批文' : '编辑批文' }}
      </div>
      <div class="erp-form-bar">
        <div class="erp-primary__btn" @click="submit()">保存</div>
        <div class="erp-default__btn" @click="back()">返回</div>
      </div>
    </div>
    <el-form :model="formData" label-position="top" ref="form" :rules="rules">
      <div class="mali-edit__form">
        <el-row :gutter="16">
          <el-col :span="12">
            <el-form-item label="抬头" prop="consigneeShortName">
              <el-select
                v-model="formData.consigneeShortName"
                class="mali-full__input"
                :disabled="formType == 2"
                clearable
                filterable
                remote
                :remote-method="getConsigneeList"
                @blur="initConsigneeList"
                placeholder="请选择抬头"
              >
                <el-option v-for="item in consigneeList" :key="item.id" :label="item.shortName" :value="item.shortName"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="口岸" prop="portName">
              <el-select
                v-model="formData.portName"
                class="mali-full__input"
                :disabled="formType == 2"
                clearable
                filterable
                remote
                :remote-method="getPortList"
                @blur="initPortList"
                @change="changePortName"
                placeholder="请选择口岸"
              >
                <el-option v-for="item in portList" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="国家" prop="country">
              <el-select
                v-model="formData.country"
                :filterable="true"
                class="mali-full__input no-icon"
                placeholder="请选择国家"
                @change="changeCountry"
                :disabled="formType == 2"
              >
                <el-option
                  v-for="(item, index) in Options.goods_country"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                  :disabled="item.label == '中国'"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="厂号" prop="plantNo">
              <el-select
                multiple
                :multiple-limit="10"
                v-model="formData.plantNo"
                clearable
                filterable
                :disabled="formType == 2"
                placeholder="请选择厂号"
                class="mali-full__input tag-scroll no-icon"
              >
                <el-option v-for="item in plantNoList" :value="item" :label="item" :key="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="品种" prop="varietyName">
              <el-select
                v-model="formData.varietyName"
                class="mali-full__input"
                clearable
                filterable
                :disabled="formType == 2"
                remote
                :remote-method="getVarietyList"
                @blur="initVarietyList"
                placeholder="请选择品种"
              >
                <el-option v-for="(item, index) in varietyList" :key="index" :label="item.varietyName" :value="item.varietyName"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="冷库" prop="storageId">
              <el-select
                v-model="formData.storageId"
                class="mali-full__input"
                clearable
                filterable
                remote
                :remote-method="getStorageList"
                @blur="initStorageList"
                placeholder="请选择冷库"
              >
                <el-option v-for="(item, index) in storageList" :key="index" :label="item.shortName" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="证号" prop="certificateNumber">
              <el-input
                :maxlength="12"
                v-model="formData.certificateNumber"
                clearable
                placeholder="请输入证号"
                @blur="changeCertificateNumber"
                class="mali-full__inputcom"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="获批量" prop="permitWeight">
              <el-input
                :maxlength="8"
                v-model="formData.permitWeight"
                clearable
                @blur="changePermitWeight"
                placeholder="请输入获批量"
                class="mali-full__inputcom"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="申请日" prop="applyDate">
              <el-date-picker v-model="formData.applyDate" type="date" placeholder="请选择申请日" style="width: 100%" prefix-icon=" "></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="有效期" prop="validDate">
              <el-date-picker v-model="formData.validDate" type="date" placeholder="请选择有效期" prefix-icon=" " style="width: 100%"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="备注" prop="remark">
              <el-input
                :maxlength="300"
                v-model="formData.remark"
                clearable
                placeholder="请输入备注"
                type="textarea"
                class="mali-full__inputcom h88"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>
  </div>
</template>
<script>
import { store } from '@/store';
import { httpPost } from '@/api';
import { formatTime } from '@/utils/publicMethods';
export default {
  props: ['formType', 'data'],
  emits: ['cancel'],
  data() {
    return {
      formData: {
        // 绑定表单数据
        applyDate: null,
        certificateNumber: '',
        consigneeShortName: '',
        country: '',
        id: null,
        permitWeight: null,
        plantNo: [],
        portName: '',
        remark: '',
        storageId: null,
        validDate: null,
        varietyName: '',
        version: null,
      },
      rules: {
        // 检验规则
        consigneeShortName: [
          {
            required: true,
            message: '请选择抬头',
            trigger: 'change',
          },
        ],
        portName: [
          {
            required: true,
            message: '请选择口岸',
            trigger: 'change',
          },
        ],
        country: [
          {
            required: true,
            message: '请选择国家',
            trigger: 'change',
          },
        ],
        plantNo: [
          {
            required: true,
            message: '请选择厂号',
            trigger: 'change',
          },
        ],
        varietyName: [
          {
            required: true,
            message: '请选择品种',
            trigger: 'change',
          },
        ],
        storageId: [
          {
            required: true,
            message: '请选择冷库',
            trigger: 'change',
          },
        ],
        certificateNumber: [
          {
            required: true,
            message: '请输入证号',
            trigger: 'blur',
          },
          {
            len: 12,
            message: '证号固定12个字符',
            trigger: 'blur',
          },
        ],
        permitWeight: [
          {
            required: true,
            message: '请输入获批量',
            trigger: 'blur',
          },
        ],
        applyDate: [
          {
            required: true,
            message: '请选择申请日',
            trigger: 'change',
          },
        ],
        validDate: [
          {
            required: true,
            message: '请选择有效期',
            trigger: 'change',
          },
        ],
      },

      Options: { goods_country: store.state.dict.options.goods_country },
      consigneeList: [], // 抬头列表
      portList: [], // 口岸列表
      plantNoList: [], // 厂号列表
      varietyList: [], // 品种列表
      storageList: [], // 冷库列表
    };
  },
  methods: {
    submit() {
      // 提交动作
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saveSubmitForm();
        }
      });
    },
    async saveSubmitForm() {
      // 保存或编辑表单
      const params = JSON.parse(JSON.stringify(this.formData));
      params.validDate = formatTime(params.validDate, 'YYYY-MM-DD');
      params.applyDate = formatTime(params.applyDate, 'YYYY-MM-DD');
      const res = await httpPost('/malicrm/license/saveLicense', params);
      if (res.code === 200) {
        this.successTip('保存成功');
        this.$emit('cancel', true); // 通知列表页面关闭弹窗 true 刷新列表 false不刷新列表
      } else {
        this.errorTip(res.message);
      }
    },
    back() {
      // 返回操作
      this.$emit('cancel', false);
    },
    changeCertificateNumber(e) {
      let value = e.target.value;
      value = value.replace(/[^a-zA-Z\d]/g, ''); // 清除“数字”和“.”以外的字符
      this.formData.certificateNumber = value;
    },
    changePermitWeight(e) {
      let value = e.target.value;
      value = value.replace(/[^\d]/g, ''); // 清除“数字”和“.”以外的字符
      this.formData.permitWeight = value;
    },
    changeCountry(value) {
      // 改变国家时重新获取厂号品种信息
      this.formData.plantNo = [];
      this.formData.varietyName = null;
      this.getPlantNoList(value);
      this.getVarietyList('');
    },
    async getPlantNoList(value) {
      // 获取厂号列表
      const res = await httpPost('/malicrm/approval/queryPlantNo', {
        country: value,
      });
      if (res.code === 200) {
        this.plantNoList = res.result;
      }
    },
    initVarietyList() {
      if (this.portList && this.portList.length <= 0) {
        this.getVarietyList('');
      }
    },
    async getVarietyList(name) {
      const res = await httpPost('/malicrm/variety/queryByCountry', {
        country: this.formData.country,
        varietyName: name,
      });
      if (res.code === 200) {
        this.varietyList = res.result;
      }
    },
    initStorageList() {
      if (this.formData.portName !== null && this.formData.portName !== '') {
        if (this.storageList && this.storageList.length <= 0) {
          this.getStorageList('');
        }
      }
    },
    async getStorageList(name) {
      // 获取冷库地址
      if (this.formData.portName && this.formData.portName !== '') {
        const res = await httpPost('/malicrm/storage/queryByName', {
          shortName: name,
          portName: this.formData.portName,
        });
        if (res.code === 200) {
          this.storageList = res.result;
        }
      }
    },
    initPortList() {
      // 失去焦点时重置筛选条件
      if (this.portList && this.portList.length <= 0) {
        this.getPortList('');
      }
    },
    changePortName() {
      this.formData.storageId = null;
      this.getStorageList('');
    },
    async getPortList(name) {
      // 获取口岸列表
      const res = await httpPost('/malicrm/port/queryByName', {
        portName: name,
      });
      if (res.code === 200) {
        this.portList = res.result;
      }
    },
    initConsigneeList() {
      // 失去焦点时重置筛选条件
      if (this.consigneeList && this.consigneeList.length <= 0) {
        this.getConsigneeList('');
      }
    },
    async getConsigneeList(name) {
      // 获取抬头列表
      const res = await httpPost('/malicrm/consignee/queryIsManageByName', {
        portName: name,
      });
      if (res.code === 200) {
        this.consigneeList = res.result;
      }
    },
  },
  created() {
    if (this.formType === 2) {
      this.formData = JSON.parse(this.data);
      this.getStorageList('');
      this.getConsigneeList('');
      this.getPortList('');
      this.getPlantNoList(this.formData.country);
    } else {
      this.getConsigneeList('');
      this.getPortList('');
    }
  },
};
</script>
<style lang="scss" scoped></style>
