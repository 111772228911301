
import { defineComponent, ref } from 'vue';
import ErpList from '@/components/ErpList/ErpList.vue';
import useListData from './components/useListData';
import useVisible from '@/hooks/useVisible';
import LicenseForm from './components/Form.vue';
import { loadPage } from '@/utils/publicMethods';

export default defineComponent({
  name: 'LicenseList',
  components: { ErpList, LicenseForm },
  props: {},
  setup() {
    const { columnList, tableRef } = useListData();
    const { visible, showVisible, closeVisible } = useVisible();
    const rowId = ref('');
    const customParams: any = ref();
    // 刷新列表
    const refreshTable = (status = false) => {
      tableRef.value.refreshTable(status);
    };
    // 返回
    const cancel = (status = false) => {
      closeVisible();
      if (status) {
        refreshTable();
      }
    };
    // 查看详情
    const rowClick = (row) => {
      loadPage('LicenseOrderList', {
        id: row.id,
        version: row.version,
        data: JSON.stringify(row),
      });
    };

    return {
      cancel,
      closeVisible,
      customParams,
      columnList,
      tableRef,
      refreshTable,
      visible,
      showVisible,
      rowClick,
      rowId,
    };
  },
});
