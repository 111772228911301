import { ErpTableOptions } from '@/types/type';
import dayjs from 'dayjs';
import { ref } from 'vue';
export default () => {
  const tableRef = ref();

  const columnList: ErpTableOptions[] = [
    {
      label: '抬头', // 表头标签
      prop: 'consigneeShortName', // 属性
      type: 'input',
      value: '',
      minWidth: 120,
    },
    {
      label: '口岸', // 表头标签
      prop: 'portName', // 属性
      type: 'input',
      value: '',
      minWidth: 120,
    },
    {
      label: '国家',
      prop: 'country',
      propDesc: 'countryDisplay',
      type: 'input',
      value: '',
      minWidth: 120,
    },
    {
      label: '厂号',
      prop: 'plantNo',
      propDesc: 'plantNoMerged',
      type: 'input',
      value: '',
      minWidth: 120,
    },
    {
      label: '品种',
      prop: 'varietyName',
      type: 'input',
      value: '',
      minWidth: 120,
    },
    {
      label: '证号',
      prop: 'certificateNumber',
      type: 'input',
      value: '',
      minWidth: 120,
    },
    {
      label: '冷库',
      prop: 'storageShortName',
      type: 'input',
      value: '',
      minWidth: 120,
    },
    {
      label: '获批量(kg)',
      prop: 'permitWeight',
      filterProp: ['permitWeightMinVal', 'permitWeightMaxVal'],
      propDesc: 'permitWeightDisplay',
      minWidth: 160,
      type: 'number', // 筛选类型 number
      value: [],
    },
    {
      label: '申请日',
      prop: 'applyDate',
      filterProp: ['applyStartDate', 'applyEndDate'],
      type: 'time', // 筛选类型 time, // 筛选类型 number
      value: [],
      minWidth: 120,
    },
    {
      label: '有效期',
      prop: 'validDate',
      filterProp: ['validStartDate', 'validEndDate'],
      type: 'time', // 筛选类型 time
      value: [dayjs().format('YYYY-MM-DD'), '9999-12-31'],
      minWidth: 120,
    },
    {
      label: '预计余量(kg)',
      prop: 'leftWeightDisplay',
      minWidth: 150,
      type: 'number', // 筛选类型 number
      filterProp: ['leftWeightMinVal', 'leftWeightMaxVal'],
      value: [],
    },
    {
      label: '备注',
      prop: 'remark',
      type: 'input',
      value: '',
      minWidth: 160,
    },
  ];
  return { columnList, tableRef };
};

//
